import { createRouter, createWebHistory } from "vue-router";
import authRoutes from "./routes/auth/authRoutes";
import superAdminRoutes from "./routes/superAdmin/superAdminRoutes";
import homeRoutes from "./routes/homeRoutes";
import { useToast } from "vue-toastification";
const Toast = useToast();

const routes = [...homeRoutes, ...authRoutes, ...superAdminRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const baseTitle = "Survey hub";
//   const routeTitle = to.meta.title ? ` - ${to.meta.title}` : "";
//   document.title = baseTitle + routeTitle;
//   next();
// });

router.beforeEach((to, from, next) => {

  // Set the document title based on route meta
  const baseTitle = "Survey Hub";
  const routeTitle = to.meta.title ? ` - ${to.meta.title}` : "";
  document.title = baseTitle + routeTitle;

  const isAuthenticated = localStorage.getItem("iLi")
    ? localStorage.getItem("iLi") === "true"
    : sessionStorage.getItem("iLi") === "true";

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    Toast.error("Please first login!");
    next({
      name: "login",
      query: { redirect: to.fullPath }, // Pass the path to redirect the user after login
    });
  } else if (to.matched.length === 0) {
    // If the route does not exist, redirect to the current route
    Toast.error("Error 404: Page doesn't Exist");
    next({ ...from, replace: true });
  } else if (isAuthenticated && to.name === "login") {
    // If user is authenticated and tries to access login page, redirect to a given route
    next({ name: "super-dashboard" }); // Redirect to dashboard if already logged in
  } else if (isAuthenticated && to.name === "home") {
    next({ name: "super-dashboard" });
  } else {
    next(); // Continue to the route
  }
});





export default router;
